/******* screen-small.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* fontface.less 2013-1-16 *******/
@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'cbfont';
  src: url('/font/cbfont.eot');
  src: url('/font/cbfont.eot?#iefix') format('embedded-opentype'), url("/font/cbfont.woff") format('woff'), url("/font/cbfont.ttf") format('truetype'), url("/font/cbfont.svg#cbfont") format('svg');
}
@font-face {
  font-family: 'jwa-icons';
  src: url('/extras/fonts/jwa-icons.eot?hook48');
  src: url('/extras/fonts/jwa-icons.eot?#iefixhook48') format('embedded-opentype'), url('/extras/fonts/jwa-icons.woff?hook48') format('woff'), url('/extras/fonts/jwa-icons.ttf?hook48') format('truetype'), url('/extras/fonts/jwa-icons.svg?hook48#jwa-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
/******* reset.less 2013-1-16 *******/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
abbr,
code,
em,
hr,
img,
strong,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
input,
textarea,
button,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
audio,
video {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0 #fff;
  /* color for input.file */
  background: transparent;
  vertical-align: baseline;
  font-size: 100%;
}
ul {
  list-style: none;
}
a:active,
a:focus {
  outline: none;
}
img {
  display: block;
  /* risky, was #root div.show img, cut at #disp .body img, */
  border: none;
  -ms-interpolation-mode: bicubic;
}
/******* cmsbox.less 2013-1-16 *******/
audio {
  float: left;
  width: 100%;
}
#desk,
.unit,
.part {
  position: relative;
}
.stop,
.unit > .foot {
  clear: both;
}
#head,
.area,
.unit,
.unit > .body,
.unit > form > .body,
.part {
  float: left;
}
.area {
  min-height: 280px;
}
.unit {
  margin-top: 20px;
  margin-bottom: 20px;
}
.unit > .body,
.unit > form > .body {
  margin: 0.4em 0;
  min-height: 2em;
  width: 100%;
}
.cb-part-body {
  margin-top: 0.4em;
  margin-bottom: 0.4em;
}
.fold > .body {
  min-height: 0;
}
.part,
.grid table {
  margin-top: 0.4em;
  margin-bottom: 0.4em;
}
.lead {
  margin-bottom: 0 !important;
}
.list {
  margin-top: 0.2em !important;
  margin-bottom: 0.2em !important;
}
.gist,
.dent {
  display: list-item;
  list-style-position: outside;
}
.gist {
  margin-left: 16px;
}
.dent {
  margin-left: 32px;
}
.line {
  margin-top: 0;
  margin-bottom: 0;
  padding: 2px 0;
  font-size: 0;
  line-height: 0;
}
.line > hr {
  overflow: hidden;
  height: 1px;
  border-color: #599d3b;
  color: #599d3b;
  font-size: 0;
  line-height: 0;
}
.hair {
  border-bottom-style: solid;
}
.dash {
  border-bottom-style: dashed;
}
.spot {
  border-bottom-style: dotted;
}
.fine {
  border-bottom-width: 1px;
}
.bold {
  border-bottom-width: 2px;
}
.link > .open {
  display: block;
  margin-left: 14px;
}
.link {
  background-repeat: no-repeat !important;
}
a.link {
  background: none;
}
div.load {
  background: none !important;
}
div.load a.load {
  display: inline-block;
  padding-left: 26px;
  background-size: 16px 16px;
  background-position: 0 4px;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-download-black.svg);
}
.null,
.null:hover,
.null:focus {
  color: #254118 !important;
  text-decoration: none !important;
  cursor: default;
}
.pict > img,
.pict > a > img,
.file > img,
.file > a > img,
.film > img,
.film > a > img,
.play > img,
.cb-googlemapscontainer,
.cb-zoom-image {
  width: 100%;
}
a.zoom {
  position: relative;
  display: block;
}
img.zoom {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -18px 0 0 -17px !important;
  width: 35px !important;
  height: 35px;
}
.cb-zoom-image {
  overflow: hidden;
  margin: 0 auto;
  color: #ccc;
  font-size: 12px;
  font-family: monaco, 'courier new', courier, monospace;
}
.crop {
  position: relative;
  overflow: hidden;
  height: 320px;
}
.crop > img {
  position: absolute;
  width: auto;
}
.plug {
  overflow: hidden;
  background-position: 50%;
  background-repeat: no-repeat;
}
p.plug {
  max-width: 100%;
  background-size: contain;
}
.flash,
.flash embed,
.flash object,
.crossslide {
  overflow: hidden;
  width: 100%;
}
div.flash > div {
  width: 100%;
  height: 100%;
}
.cb-youtube > .flash,
.cb-youtube > .null {
  background: url('/icons/youtube_logo.png') no-repeat 50%;
}
.cb-vimeo > .flash,
.cb-vimeo > .null {
  background: url('/icons/vimeo_logo.png') no-repeat 50%;
}
.cb-youtube > .cb-youtube,
.cb-vimeo > .cb-vimeo {
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
}
.cb-twitter ul {
  list-style-type: none;
}
.cb-twitter li {
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #599d3b;
}
.cb-twittercontainer span {
  font-size: 0.95em;
}
.cb-twitterfeed {
  display: block;
  padding: 5px;
  background-color: #eee;
}
.cb-statistics .cb-loading,
.cb-twittercontainer .cb-loading {
  margin: 10px auto 25px;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background: #111 url(/icons/load.gif) no-repeat 50%;
}
.cb-service > a {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  margin-top: 2px;
  margin-right: 10px;
  padding-bottom: 4px;
  width: 16px;
  height: 16px;
  background: url(/icons/email.png) no-repeat;
}
.cb-service > a:hover {
  background-position: 0px -20px;
}
.cb-service > a:active {
  background-position: 0px -40px;
}
.cb-service > .cb-delicious {
  background-image: url(/icons/delicious.png);
}
.cb-service > .cb-digg {
  background-image: url(/icons/digg.png);
}
.cb-service > .cb-twitter {
  background-image: url(/icons/twitter.png);
}
.cb-service > .cb-facebook {
  background-image: url(/icons/facebook.png);
}
.cb-service > .cb-service {
  position: relative;
  float: left;
  margin-bottom: 8px;
  padding: 2px 5px 2px 0;
  height: 24px;
  background: red;
}
/* .cb-share .cb-service-facebook-like,
.cb-share .cb-service-facebook-recommend {
  margin-bottom: 12px; */
.code pre {
  font-family: monaco, 'courier new', courier, monospace;
}
.grid table {
  border-collapse: collapse;
}
.cb-table-layout-fixed {
  table-layout: fixed;
}
.grid td,
.grid th {
  border-width: 0;
  border-style: solid;
  border-color: #666666;
  vertical-align: top;
}
.horz td,
.horz th {
  padding: 1px;
}
.vert td,
.vert th {
  padding: 1px 4px;
}
.horz .trim td,
.horz .trim th {
  border-width: 1px 0;
}
.vert .trim td .vert .trim th {
  border-width: 0 1px;
}
.grid thead td,
.grid thead th,
.grid tfoot td {
  background: #999;
  font-weight: bold;
}
.grid th {
  text-align: left;
}
.vert > .beam td.odd,
.horz > .beam > .odd {
  background: #cccccc;
}
a.plug {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  display: block;
  width: 100%;
  height: 100%;
  background: url(/objects/flashplayer/images/adobe.png) no-repeat 50%;
}
#root div.foto img.cb-loading,
#root div.foto div.cb-loading,
#root #disp img.cb-loading {
  overflow: hidden;
  outline: 1px dashed #eee;
  background: #111 url(/icons/load.gif) no-repeat 50%;
  opacity: 0.35;
}
.hidden,
.fake,
.read {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  border: 0;
}
.cb-invisible {
  display: none !important;
}
.cb-access {
  display: inline;
  float: left;
  overflow: hidden;
  width: 0;
  height: 0;
}
caption {
  display: none;
}
#root #over {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 210;
  /* .mark in edit mode z-index: 200 */
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: url(/images/over.png);
}
#disp {
  position: absolute;
  z-index: 211;
  color: #404040;
}
#disp .head,
#disp h2,
#disp .head a.quit {
  color: #fff;
  color: rgba(255, 255, 255, 0.92);
  line-height: 24px;
}
#disp .head {
  position: relative;
  overflow: hidden;
}
#disp h2 {
  font-size: 21px;
  font-size: 2.1rem;
  margin: 0 10px;
  padding-right: 30px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  letter-spacing: 0.025em;
  font-weight: 300;
}
#disp.zoom h2 {
  padding-right: 80px;
}
#disp .head .ctrl {
  position: absolute;
  top: 2px;
  right: 8px;
  width: 74px;
}
#disp .head .cb-hybrid,
#disp a.quit {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  width: 22px;
  height: 24px;
  background: url(/images/lb-ctr.png) no-repeat;
  font-size: 0;
}
#disp .head .cb-hybrid:hover,
#disp a.quit:hover,
#disp .head .cb-hybrid:focus,
#disp a.quit:focus {
  opacity: 0.75;
}
#disp .head .cb-hybrid:active,
#disp a.quit:active {
  opacity: 0.5;
}
#disp .prev > .cb-hybrid {
  background-position: 3px 50%;
}
#disp .next > .cb-hybrid {
  background-position: -15px 50%;
}
#disp a.quit {
  float: right;
  background-position: -50px 50%;
}
/* DISP BODY */
#disp .body {
  overflow: hidden;
  margin: 10px 10px 0;
  padding: 15px;
  border-radius: 2px 2px 0 0;
  background: #fff;
}
#disp.mail .body,
#disp.resp .body {
  padding-bottom: 0;
}
#disp.mail .foot,
#disp.resp .foot,
#disp.auth .foot,
#disp.cb-lightbox-code .foot,
#disp.message .foot {
  padding-bottom: 10px;
  text-align: right;
}
.mail p + fieldset + fieldset {
  min-height: 4.4em;
  /* no jumping after recipient address renders */
}
#disp a {
  color: #404040;
}
.cb-disp-sent {
  display: block;
  padding: 0.4em 14px;
  text-align: left;
}
#disp fieldset {
  padding: 0.2em 0;
}
#disp .mail,
#disp select,
#disp textarea,
#disp .tick,
#disp .text,
#disp .file,
#disp .password {
  display: block;
  width: 100%;
}
#disp textarea {
  height: 160px;
}
#disp label.name {
  display: block;
  width: 100%;
  font-weight: bold;
}
#disp label.sign {
  float: right;
  /* used where ? */
  width: 278px;
}
#disp div.tack {
  float: right;
  width: 20px;
}
/* DISP FOOT */
#disp .foot {
  overflow: hidden;
  margin: 0 10px;
  padding-bottom: 0.5em;
  border-radius: 0 0 2px 2px;
  background: #fff;
  text-align: left;
}
#disp .foot p.prev,
#disp .foot p.next {
  float: left;
  /* used where ? */
  width: 40%;
}
#disp .foot p.next {
  /* used where ? */
  float: right;
  text-align: right;
}
#disp .foot input.prev,
#disp .canc,
#disp .foot .button {
  margin-left: 15px;
}
#disp .submit,
#disp .foot input.next,
#disp .conf,
#disp .foot .button {
  margin-right: 15px;
}
a.capt {
  float: left;
  margin-bottom: 0.5em;
  padding: 0.3em 0;
  background: #000;
}
#disp a.capt {
  width: 100%;
}
img.capt {
  margin: 0 auto;
}
#disp.zoom .foot,
#disp.zoom .foot > fieldset {
  padding-bottom: 0;
}
.zoom fieldset > p {
  padding: 0 15px 25px;
  color: #404040;
}
#disp > .body > .part {
  margin: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
#disp.film p.plug {
  width: 100%;
  height: 100%;
}
#disp .part > .flash {
  max-width: 100%;
}
/* CODE */
#disp.cb-lightbox-code textarea {
  height: 400px;
  font-family: monaco, 'courier new', courier, monospace;
}
/* DISP SITEMAP */
.cb-sitemap-target {
  font-size: 12px;
  font-size: 1.2rem;
  overflow: hidden;
  margin-bottom: 1em;
  padding-bottom: 5px;
  color: #e64246;
  text-overflow: ellipsis;
}
#disp.site li,
div.cb-sitemap li {
  font-size: 15px;
  font-size: 1.5rem;
  margin: 5px 0 0 16px;
  list-style: url(/images/list-sitemap.png);
}
#disp.srch a,
#disp.site li > a,
div.cb-sitemap a {
  display: block;
  color: #404040;
  text-decoration: none;
}
#disp.srch a:hover,
#disp.site li > a:hover,
div.cb-sitemap a:hover,
#disp.srch a:focus,
#disp.site li > a:focus,
div.cb-sitemap a:focus {
  text-decoration: underline;
}
/*#disp.site h2 {
  letter-spacing: normal; // german version "Die Seite wurde nicht gefunden" has to fit in head
  .font-size(18);
}*/
/* DISP SEARCH */
#disp.srch th,
#disp.srch td {
  padding: 0 2px;
  vertical-align: top;
  text-align: left;
}
#disp.srch th:first-child {
  text-align: right;
}
#disp.srch td {
  /* overwrite default */
  padding-bottom: 0.8em;
}
.flag {
  background-color: #ffffff;
  color: #fd0;
  text-shadow: none;
}
::-moz-selection {
  background-color: #a3d48e;
  color: #000000;
  text-shadow: none;
}
::selection {
  background-color: #a3d48e;
  color: #000000;
  text-shadow: none;
}
/* NEWSLETTER */
div.prog p,
div.prog img {
  float: left;
  margin-bottom: 10px;
  margin-left: 10px;
}
#disp.two-step-verification {
  z-index: 3100;
  width: 320px;
  left: 50% !important;
  transform: translateX(-50%);
  margin-left: 0;
}
#disp.two-step-verification .two-step-verification-container {
  position: relative;
  width: 300px;
  height: 350px;
  margin-left: -15px;
  background: #fff;
}
#disp.two-step-verification .two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  width: 100%;
  height: 100%;
}
#disp.two-step-verification .two-step-verification__a {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-height: 40px;
  border-radius: 0;
  background-color: #29481b;
  padding: 0 20px;
  color: #fff;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: none;
  opacity: 0;
}
#disp.two-step-verification .loaded .two-step-verification__a {
  opacity: 1;
  transition: opacity 200ms ease-out 150ms;
}
#disp.two-step-verification .two-step-verification-container:before {
  content: "";
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 15%;
  display: block;
  width: 24px;
  height: 24px;
  margin: -12px 0 0 -12px;
  border: 3px solid rgba(113, 113, 113, 0.3);
  border-radius: 50%;
  border-top-color: #29481b;
  animation: spin 1s linear infinite;
  opacity: 1;
  transition: opacity 200ms ease-in;
}
#disp.two-step-verification .two-step-verification-container.loaded:before {
  opacity: 0;
}
.ie9 #disp .two-step-verification__a {
  display: block;
  line-height: 40px;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
/******* hide-non-mobile.less 2013-1-16 *******/
.body-mobile,
.cb-mobile .cb-mobile-invisible,
.cb-mobile .body-non-mobile {
  display: none;
}
.cb-mobile .body-mobile {
  display: block;
}
/******* site.less 2013-1-16 *******/
html,
body {
  min-height: 100%;
}
html {
  background: #fff;
  font-size: 62.5%;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(41, 72, 27, 0.4);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  font-size: 16px;
  font-size: 1.6rem;
  width: 100%;
  background-color: transparent;
  color: #254118;
  font-family: 'helvetica neue', sans-serif;
  line-height: 1.5em;
}
html {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  background-attachment: fixed;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
#view .home {
  display: none;
}
#wrapper,
#header,
#content,
#footer {
  *zoom: 1;
}
#wrapper:before,
#header:before,
#content:before,
#footer:before,
#wrapper:after,
#header:after,
#content:after,
#footer:after {
  display: table;
  content: '';
}
#wrapper:after,
#header:after,
#content:after,
#footer:after {
  clear: both;
}
@media (min-width: 980px) {
  body.c2 #navigation,
  body.c3 #navigation {
    display: none;
  }
}
#maincontent {
  float: left;
  width: 100%;
  background: #fff;
}
#head {
  margin-top: 20px;
}
h1,
.unit h2 {
  font-weight: normal;
  font-size: 32px;
  font-size: 3.2rem;
  line-height: 1.2;
  font-family: 'Arvo', sans-serif;
}
.unit h2 {
  font-size: 24px;
  font-size: 2.4rem;
}
.unit h2 .open {
  color: #5ba13d;
}
h3,
h4,
h5,
h6 {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: normal;
  font-family: 'Arvo', sans-serif;
}
h3 {
  font-size: 20px;
  font-size: 2rem;
}
h4 {
  font-size: 18px;
  font-size: 1.8rem;
}
h6 {
  font-size: 15px;
  font-size: 1.5rem;
}
strong {
  font-weight: bold;
}
.skew {
  font-size: 17px;
  font-size: 1.7rem;
  line-height: 1.7;
  font-style: italic;
  font-family: georgia, serif;
}
.side .skew {
  font-size: 15px;
  font-size: 1.5rem;
}
a {
  color: #64b042;
  text-decoration: none;
}
a:hover {
  color: #64b042;
  text-decoration: underline;
}
a:focus {
  color: #64b042;
}
.unit .open:visited {
  color: #518e36;
}
.unit .open:hover,
.unit .open:focus {
  color: #64b042;
}
.link {
  background: url(/images/link.png) no-repeat 0 0.3em;
}
.link.mail {
  background-image: url(/icon-link/mail.gif);
}
.rss {
  background-image: url(/icon-link/rss_small.gif);
}
div.form .cb-form-required {
  font-size: 10px;
}
.lead + .link {
  margin-top: 0.8em;
}
.side {
  background: #fff;
}
.unit .head {
  padding: 0.4em 0;
}
.seam {
  margin-bottom: 0;
  background: #f0f0f0;
}
.seam .head {
  background: #000000;
}
body.c3 .seam .head {
  background-color: #c8a87a;
}
.seam h2 {
  color: #fff;
}
.seam .body {
  padding-bottom: 20px;
}
.edge {
  background: #85c669;
}
body.c3 .edge {
  background-color: #90ada5;
}
.edge a {
  color: #254118;
  text-decoration: underline;
}
.edge a:hover,
.edge a:focus {
  color: #fff !important;
}
.edge.form {
  padding-top: 16px;
}
.edge.form .recaptcha-info {
  color: rgba(255, 255, 255, 0.6);
  font-size: 10px;
  line-height: 1.4;
}
.edge.form .recaptcha-info a {
  color: rgba(255, 255, 255, 0.6);
}
.edge.form .recaptcha-info a:hover,
.edge.form .recaptcha-info a:focus {
  color: #fff;
}
.edge.form .submit {
  background-color: #fff;
  appearance: none;
  border: none;
  border-radius: 0;
  padding: 6px 10px 4px;
}
.edge.form .submit:hover,
.edge.form .submit:focus {
  background-color: rgba(255, 255, 255, 0.8);
}
.cb-mobile-invisible .beta span > .cb-hybrid,
.cb-mobile-invisible .days td > .cb-hybrid,
.cb-mobile-invisible .week td > .cb-hybrid,
.cb-mobile-invisible .year td > .cb-hybrid,
.cb-mobile-invisible .cats li > .cb-hybrid {
  text-decoration: underline;
}
.grow {
  text-transform: none !important;
}
.cb-strips-4 .cb-strips-thumbs .part {
  margin: 3% !important;
  width: 19% !important;
}
.cb-strips-6 .cb-strips-thumbs .part {
  margin: 3% !important;
  width: 10.5% !important;
}
.cb-strips-8 .cb-strips-thumbs .part {
  margin: 3% !important;
  width: 6.5% !important;
}
.cb-strips-10 .cb-strips-thumbs .part {
  margin: 3% !important;
  width: 4% !important;
}
.cb-matrix-columns-1 .part.foto {
  margin: 3% !important;
  width: 94% !important;
}
.cb-matrix-columns-2 .part.foto {
  margin: 3% !important;
  width: 44% !important;
}
.cb-matrix-columns-3 .part.foto {
  margin: 3% !important;
  width: 27% !important;
}
.cb-matrix-columns-4 .part.foto {
  margin: 3% !important;
  width: 19% !important;
}
.cb-matrix-columns-5 .part.foto {
  margin: 3% !important;
  width: 14% !important;
}
.cb-matrix-columns-6 .part.foto {
  margin: 3% !important;
  width: 10.5% !important;
}
.cb-matrix-columns-7 .part.foto {
  margin: 3% !important;
  width: 8% !important;
}
.cb-matrix-columns-8 .part.foto {
  margin: 3% !important;
  width: 6.5% !important;
}
.cb-strips-thumbs .body {
  width: 100%;
}
.cb-slideshow .part {
  height: 100% !important;
}
.crossslide img {
  max-height: 100% !important;
}
.cb-strips-images {
  width: 100%;
  height: 100%;
}
.cb-album .cb-strips-images a.foto {
  width: 100%;
  height: 100%;
}
.cb-album .cb-strips-images .cb-portrait {
  max-height: 100% !important;
  width: auto !important;
}
.cb-album .cb-strips-thumbs .part img {
  opacity: 1 !important;
}
.cb-vimeo iframe,
.cb-youtube iframe {
  width: 100%;
  height: 100%;
  display: block;
  border: none;
  /* iframe borderframe */
}
.item-empty .hover {
  display: none !important;
}
#edit div.code {
  min-height: 120px;
  background: url(/images/code-logo.png) no-repeat 50% 50%;
}
#edit div.code .pick {
  background: #bdf url(/images/code-logo.png) no-repeat 50% 50% !important;
}
#edit div.code button {
  position: absolute;
  bottom: 0;
  left: 0;
}
#disp.cb-lightbox-code textarea {
  width: 430px;
}
#disp.cb-lightbox-code input.conf {
  margin-right: 15px;
}
/******* forms.less 2013-1-16 *******/
fieldset {
  line-height: 1.3em;
}
fieldset .name,
label.sign {
  line-height: 1.2em;
  padding-top: 0;
}
.form .name {
  padding-bottom: 0.2em;
}
input,
button,
select,
textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
input.text,
textarea {
  color: #404040;
  background-color: #fff;
  font-weight: normal;
  font-family: 'helvetica neue', helvetica, arial, sans-serif;
  font-size: 12px;
  font-size: 1.2rem;
}
input.text,
input.sign,
input.password,
textarea,
input.hint,
textarea.hint,
input.capt {
  padding: 0.13333333em 3px;
  min-height: 1.3em;
  border: 1px solid #bfbfbf;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
}
select {
  font-size: 12px;
  font-size: 1.2rem;
  color: #444;
  font-weight: normal;
  font-family: 'helvetica neue', helvetica, arial, sans-serif;
  line-height: 16px;
}
textarea {
  overflow: auto;
}
input.text[disabled],
textarea[disabled] {
  border-color: #cccccc;
  -webkit-box-shadow: none;
  box-shadow: none;
}
input.hint {
  color: #808080;
}
textarea:focus,
input.text:focus,
input.sign:focus,
input.password:focus {
  outline: none;
  border-color: #99b5de;
  -webkit-box-shadow: 0 0 3px rgba(153, 181, 222, 0.7);
  box-shadow: 0 0 3px rgba(153, 181, 222, 0.7);
  -webkit-transition: -webkit-box-shadow 0.218s;
  -moz-transition: box-shadow 0.218s;
  -o-transition: box-shadow 0.218s;
  transition: box-shadow 0.218s;
}
input.submit,
button.cb-hybrid {
  font-size: 12px;
  font-size: 1.2rem;
  display: inline-block;
  padding: 0.13333333em 1em;
  min-height: 1.3em;
  border: 1px solid;
  border-color: #bfbfbf #a6a6a6 #a6a6a6 #bfbfbf;
  border-radius: 2px;
  background-color: #ccc;
  color: #404040;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  line-height: 1.3em;
}
input.submit:hover,
button.cb-hybrid:hover,
input.submit:focus,
button.cb-hybrid:focus {
  border-color: #b3b3b3 #999999 #999999 #b3b3b3;
  background-color: #d4d4d4;
  color: #333333;
  text-decoration: none;
  cursor: pointer;
}
input.submit:active,
button.cb-hybrid:active {
  outline: none;
  border-color: #8c8c8c #a6a6a6 #a6a6a6 #8c8c8c;
  background-color: #b3b3b3;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  text-shadow: none;
}
.form .foot,
.auth .foot {
  padding-bottom: 1em;
  text-align: right;
}
.form select,
.form textarea,
.form input.text,
.form input.file,
.form .name,
.form a.capt {
  display: block;
  width: 100%;
}
.form textarea {
  overflow: auto;
}
.form .pile .ctrl,
.form .pile .chop {
  width: 100%;
}
.form .ship .chop {
  overflow: visible;
}
.ship .chop > a {
  margin-left: 8px;
}
.chop > .file {
  float: right;
  width: 100%;
}
input.radio,
input.checkbox {
  margin: -3px 0.3em 0 0;
  min-height: 1.3em;
  vertical-align: middle;
}
.tick input.radio,
.tick input.checkbox {
  float: left;
  margin-top: 0;
}
.tick .ctrl > div,
.tick .ctrl > div {
  clear: left;
  padding-bottom: 0.2em;
}
.form .ctrl .sign {
  display: block;
  margin-left: 20px;
}
div.cb-form-sent {
  padding: 4px 0;
  border: 1px #218823;
  border-style: solid none;
  background: #baeebb;
  text-align: center;
  float: left;
}
.cb-form-sent > .cb-form-sent {
  color: #218823;
}
.fail {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-right: 30px !important;
}
div.fail {
  background: #fef6f6 url(/icons/warning.png) no-repeat 99% !important;
  float: right;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  border: 1px #e64246;
  border-style: solid none;
  color: #e64246;
}
div.cb-lightbox-table ul.fail {
  background: #fef6f6 url(/icons/warning.png) no-repeat 99% !important;
  color: #e64246;
}
.cb-lightbox-table textarea.fail {
  background-image: none !important;
}
.fail > p,
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin: 2px 5px;
}
.fail > p {
  font-weight: 600;
}
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin-left: 2em;
  list-style: square outside;
}
input.fail,
textarea.fail {
  background: #fef6f6 url(/icons/warning.png) no-repeat 99% !important;
  border-color: #e64246;
  color: #e64246;
}
.fail .sign {
  width: 90%;
}
/* input.fade,
select.fade,
textarea.fade { // if !important added: fix shop input.payp.fade
    &,
    &:hover,
    &:focus,
    &:active {
    border-color: lighten(@input-border,25); */
/* background-color: @lightergray; */
/* background-image: none; */
/* color: lighten(@input-color, 35); */
/******* layout-small.less 2013-1-16 *******/
@media screen and (max-width: 479px) {
  html {
    background: #fff !important;
  }
}
#head {
  margin-left: 5%;
  width: 90%;
  margin-top: 3em;
}
#slides img,
#slides .slide a,
#slides .slide,
#slides,
#expo {
  max-width: 980px !important;
  width: 100%;
  margin: 0 auto;
}
#slides img,
#slides .slide {
  height: auto !important;
}
.togglenavi {
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.6;
  padding: 0.3em 0.9em 0.2em;
  border-radius: 4px;
  border: 1px solid currentcolor;
  position: fixed;
  z-index: 3;
  left: 10px;
  top: 10px;
  color: #000000;
  cursor: pointer;
  text-decoration: none !important;
  background: rgba(255, 255, 255, 0.8);
  background-clip: padding-box;
  -webkit-transition: opacity 0.6s ease-out;
  -moz-transition: opacity 0.6s ease-out;
  -o-transition: opacity 0.6s ease-out;
  transition: opacity 0.6s ease-out;
}
.togglenavi.cb-toggle-active {
  opacity: 0.4;
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
}
.togglenavi:hover,
.togglenavi:focus {
  opacity: 1;
  color: #091006;
}
.togglenavi:active {
  color: #000000;
}
@media screen and (max-width: 979px) {
  div.sub1 {
    width: 100%;
    max-height: 0;
    overflow: scroll;
    float: none;
    background: #000000;
  }
  div.sub1.cb-toggle-target-active {
    max-height: 400px;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2;
    padding-top: 3em;
  }
  .menu {
    font-family: 'Arvo', sans-serif;
    font-size: 16px;
    font-size: 1.6rem;
    display: inline-block;
    padding: 0.4em 50px 0.4em 10px;
  }
  .item-empty > .menu,
  .sub2 > .menu,
  .menu.path {
    display: block;
  }
  .menu.here {
    color: #fff;
    background: #29481b;
  }
  div.sub2,
  div.sub3 {
    background: #101b0a;
    overflow: hidden;
    max-height: 0;
  }
  .cb-toggle-target-active > div.sub2,
  .cb-toggle-target-active > div.sub3,
  .path > div.sub2,
  .path > div.sub3 {
    max-height: none;
  }
  div.sub2 .menu.path,
  div.sub3 .menu.path,
  div.sub2 .menu:hover,
  div.sub3 .menu:hover,
  div.sub2 .menu:focus,
  div.sub3 .menu:focus {
    text-decoration: none;
  }
  div.sub2 .menu:active,
  div.sub3 .menu:active {
    color: #fff;
  }
  div.sub2 div.sub3,
  div.sub3 div.sub3 {
    background: #14230d;
  }
  .item > .cb-toggle {
    font-size: 20px;
    font-size: 2rem;
    display: inline-block;
    margin: 0 -1px 0 8px;
    width: 30%;
    height: 1.7em;
    margin-left: -40px;
    vertical-align: middle;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }
  .item > .cb-toggle:after {
    font-size: 15px;
    font-size: 1.5rem;
    display: block;
    color: #29481b;
    font-family: 'jwa-icons';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e602";
    vertical-align: middle;
    line-height: 1.8;
  }
  .item > .cb-toggle-active:after {
    content: '\e601';
  }
  .item-empty > .cb-toggle,
  .path > .cb-toggle {
    display: none;
  }
}
@media (max-width: 979px) {
  body.c2 div.sub1 > .item > .cb-toggle,
  body.c3 div.sub1 > .item > .cb-toggle,
  body.c2 div.sub1 > .item > .menu,
  body.c3 div.sub1 > .item > .menu {
    display: none;
  }
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 5%;
  margin-left: 5%;
}
.area .part,
.area > .grid table {
  margin-right: 5%;
  margin-left: 5%;
  width: 90%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 90%;
}
/*.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}*/
#disp {
  left: 3%;
  margin-left: 0;
  width: 94%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 3% !important;
}
#disp.site li,
div.cb-sitemap li {
  font-size: 14px;
  font-size: 1.4rem;
}
/*#disp.cb-photoeditor {
  .disp(90); 
}*/
/*#disp.cb-lightbox-code {
  .disp(90);
}*/
/*#disp.cb-lightbox-code textarea {
  width: 428px;
}*/
/*#disp.shop,
#disp.cb-shoppingcart {
   .disp(90);
   min-width: 340px;
}*/
h1,
h2 {
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
/* scan.less 2013-1-16 */
.swap {
  text-align: center;
}
.scan form,
.scan > table,
.scan fieldset {
  width: 100% !important;
}
.scan > table {
  margin: 0 !important;
}
.swap td > span,
.swap td > .cb-hybrid {
  display: block;
}
td.prev,
td.next {
  color: rgba(100, 176, 66, 0.3);
}
.unit.cb-batch .same {
  background-color: #85c669;
  color: #254118;
}
/******* module-album.less 2013-1-16 *******/
.body-mobile img,
noscript img {
  /* resize - noscript mode must still work */
  width: 100%;
}
.part.foto {
  overflow: hidden;
}
div.foto,
.crossslideContainer {
  position: relative;
}
.crossslide,
.cb-slideshow-images img {
  width: 100%;
}
.cb-strips .foto > img,
.cb-matrix .body-mobile .foto > img,
.cb-contentflow .foto > img,
.cb-slideshow .foto > img,
.body-non-mobile .foto > img {
  margin: 0 auto;
  width: 100%;
}
.body-mobile a.foto,
.body-mobile span.foto {
  width: 100%;
}
noscript a.foto {
  position: relative !important;
}
.body-mobile .cb-matrix .foto,
.body-mobile .cb-contentflow .foto {
  position: relative;
}
.part.desc {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.same.norm,
.same.foto {
  background: none;
}
.body-non-mobile a.foto,
.body-non-mobile span.foto {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  padding: 0;
  width: 100%;
}
.cb-strips table {
  border-collapse: collapse;
}
td > .body {
  display: inline-block;
  vertical-align: middle;
}
.cb-strips-images .foto {
  position: absolute;
}
.cb-strips-images img {
  width: 100%;
}
.cb-contentflow .body-non-mobile .part {
  margin-right: 0 !important;
  margin-left: 0 !important;
  width: 100% !important;
}
.foto > .head {
  position: relative;
}
.cb-manual .desc,
.body-non-mobile > .desc {
  position: relative;
}
#view .cb-manual .desc .norm,
#view .body-non-mobile > .desc .norm {
  position: absolute;
  top: 0;
  left: 0;
}
.foto > .head > .ctrl {
  position: absolute;
  top: 2px;
  right: 8px;
}
.cb-album .ctrl {
  float: right;
}
#root div.foto div.body div.desc {
  float: left;
}
#root div.foto div.body div.desc div.part {
  float: none;
}
.aspt,
.cb-sort-mode {
  font-size: 12px;
  font-size: 1.2rem;
  display: inline-block;
  padding: 0.13333333em 1em;
  min-height: 1.3em;
  border: 1px solid;
  border-color: #bfbfbf #a6a6a6 #a6a6a6 #bfbfbf;
  border-radius: 2px;
  background-color: #ccc;
  color: #404040;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  line-height: 1.3em;
  float: left;
}
.aspt:hover,
.cb-sort-mode:hover,
.aspt:focus,
.cb-sort-mode:focus {
  border-color: #b3b3b3 #999999 #999999 #b3b3b3;
  background-color: #d4d4d4;
  color: #333333;
  text-decoration: none;
  cursor: pointer;
}
.aspt:active,
.cb-sort-mode:active {
  outline: none;
  border-color: #8c8c8c #a6a6a6 #a6a6a6 #8c8c8c;
  background-color: #b3b3b3;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  text-shadow: none;
}
.aspt {
  margin-right: 0.5em;
}
/******* module-forum.less 2013-1-16 *******/
.resp > .foot {
  margin: 0 !important;
}
.resp > .foot > .scan > .ctrl {
  margin-top: 1em;
  text-align: right;
}
/* HEAD */
.frum > .head {
  position: relative;
}
.frum .rss > a {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  position: absolute;
  top: 6px;
  right: 8px;
  width: 12px;
  height: 12px;
  background: url(/icon-link/rss.gif) no-repeat;
}
div.frum div.resp span.topi {
  display: block;
  text-align: right;
}
.resp > .link {
  background-image: none;
}
.resp > .link > .open {
  margin: 0;
}
div.frum div.resp div.link span {
  margin-left: 5px;
}
div.frum div.topi {
  padding: 0.3em 0;
  border-bottom: 1px solid #bbb;
  background-color: #efefef;
}
div.frum div.topi p,
div.frum div.topi div.link {
  margin: 0 0.5em;
}
/******* 1ST ENTRY *******/
div.frum div.pend,
div.dlog div.pend {
  color: #bbb;
}
.resp .swap > .ctrl > .cb-hybrid {
  font-size: 12px;
  font-size: 1.2rem;
  display: inline-block;
  padding: 0.13333333em 1em;
  min-height: 1.3em;
  border: 1px solid;
  border-color: #bfbfbf #a6a6a6 #a6a6a6 #bfbfbf;
  border-radius: 2px;
  background-color: #ccc;
  color: #404040;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  line-height: 1.3em;
}
.resp .swap > .ctrl > .cb-hybrid:hover,
.resp .swap > .ctrl > .cb-hybrid:focus {
  border-color: #b3b3b3 #999999 #999999 #b3b3b3;
  background-color: #d4d4d4;
  color: #333333;
  text-decoration: none;
  cursor: pointer;
}
.resp .swap > .ctrl > .cb-hybrid:active {
  outline: none;
  border-color: #8c8c8c #a6a6a6 #a6a6a6 #8c8c8c;
  background-color: #b3b3b3;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  text-shadow: none;
}
div.frum div.ctrl a.back,
div.frum div.ctrl a.resp {
  float: left;
  padding: 1px 0;
  width: 40%;
}
div.frum div.ctrl a.resp {
  float: right;
}
#edit div.frum div.ctrl a.fade,
#edit div.dlog div.ctrl a.fade {
  border: 1px solid #ccc;
  background-color: #eee;
  color: #808080;
}
.resp > .link > .cb-hybrid {
  white-space: nowrap;
}
#root #lbox div.onse {
  left: 50%;
  margin-left: -225px;
  width: 450px;
}
#root #lbox div.onse div.body div.resp {
  max-height: 350px;
  margin-top: 10px;
}
#root #lbox div.onse div.foot p {
  margin-bottom: 12px;
}
#root #lbox div.onse div.wait {
  float: left;
  margin-bottom: 8px;
  width: 100%;
  border-bottom: 2px solid #dedede;
}
#root #lbox div.onse div.wait div.resp {
  float: left;
  width: 100%;
}
#root #lbox div.onse div.link {
  background: none;
}
#root #lbox div.onse div.link a {
  float: left;
  margin-left: 0;
}
#root #lbox div.onse div.link p {
  float: right;
}
#root #lbox div.onse div.body div.ctrl {
  float: left;
  margin-bottom: 20px;
  margin-left: 0%;
  margin-top: 20px;
  width: 124px;
}
#root #lbox div.onse div.body div.ctrl input.radio {
  float: left;
  margin: 0 5px;
}
#root #lbox div.onse div.body div.ctrl label {
  float: left;
}
#root #lbox div.onse div.body div.ctrl div {
  float: left;
  width: 124px;
}
/******* module-security.less 2013-1-16 *******/
.cb-part-foot {
  clear: both;
  margin: 0 !important;
}
.cb-part-body {
  /* only used in authentication part */
  margin: 0.4em 0;
}
.auth fieldset {
  padding: 0.2em 0;
}
.auth .text,
.auth .password {
  float: right;
  width: 100%;
}
.auth .ctrl .sign {
  display: block;
  margin-left: 20px;
  line-height: 19px;
}
/******* module-statistic.less 2013-1-16 *******/
.cb-statistics .chartclient-annotation-table {
  font-size: 12px;
  font-size: 1.2rem;
}
.cb-statistic-annotation li {
  list-style: none;
}
.cb-statistics .ctrl {
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ddd;
}
.cb-statistics .ctrl input {
  margin-right: 5px;
}
.cb-statistics .ctrl label {
  margin-right: 15px;
}
.cb-statistics .ctrl a {
  float: right;
}
#root .goog-custom-button-inner-box table,
#root .chartclient-annotation-table,
#root .annotatedtimelinetable {
  margin: 0;
}
#root #scrollingListTd {
  padding: 5px 0 5px 4px;
}
#root div.cb-statistics table.horz thead th {
  border-style: none none solid;
  border-color: #555;
  background-color: transparent;
}
#root div.cb-statistics table.horz tbody td {
  padding: 2px 1px;
  border: 1px solid #ddd;
  border-style: solid none none;
}
#root div.cb-statistics table.horz .odd td {
  background-color: #f9f9f9;
}
.cb-statistics .cb-visits {
  text-align: right;
}
/*# sourceMappingURL=./screen-small.css.map */